<template>
  <div class="index">
    <navigation msg="岗位发布"></navigation>
    <div class="title">
      <div class="left">试试电脑端快速发布</div>
      <div class="right"><van-icon name="arrow" /></div>
    </div>
    <div class="step">
      <div class="step_1">2</div>
      <div class="step_xian"></div>
      <div class="step_2">3</div>
    </div>
    <div class="step_text">
      <div class="step_text_1">交付服务情况</div>
      <div class="step_text_2">面试信息情况及招聘服务说明</div>
    </div>
    <div class="item1">
      <div class="label"><span>*</span>企业面试联系人称呼</div>
      <div class="name">
        <van-field
          v-model="form.interviewer"
          placeholder="请输入企业面试联系人信息"
        />
      </div>
    </div>
    <div class="item1">
      <div class="label"><span>*</span>面试联系人手机号</div>
      <div class="name">
        <van-field v-model="form.phone" placeholder="请输入面试人手机号" />
      </div>
    </div>
    <div class="item1">
      <div class="label">面试联系人（备选）</div>
      <div class="name">
        <van-field
          v-model="form.see_man_standby"
          placeholder="请输入面试人联系人"
        />
      </div>
    </div>
    <div class="item1">
      <div class="label">面试联系人手机号（备选）</div>
      <div class="name">
        <van-field
          v-model="form.see_man_standby_tel"
          placeholder="请输入面试人手机号"
        />
      </div>
    </div>
    <div class="item1">
      <div class="label"><span>*</span>招聘联系邮箱</div>
      <div class="name">
        <van-field v-model="form.email" placeholder="请输入招聘联系邮箱" />
      </div>
    </div>
    <!-- <div class="item2">
      <div class="label"><span>*</span>面试地点地理位置</div>
      <div class="name">
        <div>可从地址复制详细地址</div>
        <van-icon name="arrow" />
      </div>
    </div> -->
    <div class="item2">
      <div class="label"><span>*</span>面试地区</div>
      <div class="name" @click="show1 = true">
        <div :style="form.company_address ? 'color:#000' : ''">
          {{ form.company_address || "请选择面试地区" }}
        </div>
        <van-icon name="arrow" />
      </div>
    </div>
    <van-popup v-model="show1" round position="bottom">
      <van-cascader
        v-model="cascaderValue"
        title="请选择岗位类型"
        :options="optionList"
        :field-names="fieldNames"
        @close="show1 = false"
        @finish="onFinish"
      />
    </van-popup>
    <div class="item1">
      <div class="label"><span>*</span>面试详细地址</div>
      <div class="name">
        <van-field
          v-model="form.detailed_address"
          placeholder="请输入面试详细地址 "
        />
      </div>
    </div>
    <div class="item2">
      <div class="label"><span>*</span>面试地点与工作地点是否相同</div>
      <div class="name" @click="show2 = true">
        <div style="color: #000">
          {{ checked ? "相同" : "不相同" }}
        </div>
        <van-icon name="arrow" />
      </div>
    </div>
    <div class="item1" v-if="!checked">
      <div class="label"><span>*</span>工作详细地址</div>
      <div class="name">
        <van-field
          v-model="form.work_areas_plus"
          placeholder="请输入面试详细地址 "
        />
      </div>
    </div>
    <van-popup v-model="show2" round position="bottom">
      <van-picker
        @confirm="onConfirm1"
        title="面试地点与工作地点是否相同"
        show-toolbar
        :columns="columns1"
        @cancel="show2 = false"
      />
    </van-popup>
    <!-- <div class="item2">
      <div class="label"><span>*</span>工作地点繁荣情况</div>
      <div class="name">
        <div>属于城市核心行政区</div>
        <van-icon name="arrow" />
      </div>
    </div> -->
    <div style="height: 2rem"></div>
    <div class="footer">
      <div class="btn1" @click="save">保存</div>
      <div class="btn2" @click="back">上一步</div>
      <div class="btn3" @click="toPage">下一步</div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";

export default {
  data() {
    return {
      checked: true,
      value: "",
      value2: "",
      fieldNames: {
        text: "name",
        value: "name",
        children: "children",
      },
      cascaderValue: "",
      optionList: [],
      show1: false,
      show2: false,
      show3: false,
      columns1: ["相同", "不相同"],
      form: {
        interviewer: "", // 面试联系人
        phone: "", // 面试联系人手机号
        company_address: "", // 公司地址
        detailed_address: "", // 公司详细地址
        work_areas: "", // 工作地址
        work_areas_plus: "", // 工作详细地址
        email: "", // 面试联系人邮箱
        see_man_standby: "", // 面试联系人（备选）
        see_man_standby_tel: "", // 面试联系人手机号（备选）
        prosperity: "",

        // without: "", // 不考虑范围（不对外展示）
        // is_lbls_interview: 2, // 是否萝卜猎手首轮面试
        // interview_rounds: 1, //面试轮次
        // interview_man: "", //面试官职位
        // expire: 2, //悬赏有效期
        // post_suitable: 1, //是否需要岗位适配度报告1-需要 0-不需要
        // survey: 0, //是否需要问卷调查1-需要 0-不需要
        // backgroud_report: 1, //是否需要背调报告 1-需要 0-不需要
        // service_content: 1, //服务内容 1-入职成功 2-入职过保
        // guarantee_time: 1, //保障时间(天数): 1-7天 2-10天 3-90天
      },
    };
  },
  computed: {
    // 引入vuex中存储的数据
    ...mapState({
      forms: (state) => state.form,
      poststatus: (state) => state.poststatus,
    }),
  },

  methods: {
    save() {
      this.form = Object.assign({}, this.forms, this.form);
      this.$store.commit("SET_POST", this.form);
      this.$toast("保存成功");
    },
    onConfirm1(value, index) {
      this.checked = value == "相同" ? true : false;
      this.show2 = false;
    },
    back() {
      this.$router.go(-1);
    },
    toPage() {
      this.form = Object.assign({}, this.forms, this.form);
      this.$store.commit("SET_POST", this.form);
      this.$router.push({
        path: "/step3",
      });
    },
    onFinish({ selectedOptions }) {
      this.show1 = false;
      this.form.company_address = selectedOptions
        .map((option) => option.name)
        .join("/");
    },
  },
  async mounted() {
    if (Object.keys(this.forms).length != 0) {
      Object.keys(this.forms).forEach((key) => {
        this.form[key] = this.forms[key];
      });
    }
    await this.$http
      .post("/firm/v1/profile/district", {
        reqType: "profile",
      })
      .then((res) => {
        res = JSON.parse(res.data);
        this.optionList = res.data[0].children;
        console.log(this.optionList, "this.optionList");
      });
  },
};
</script>

<style lang='scss' scoped>
.index {
  background-color: #fff;
}
.index /deep/ .van-picker-column__item--selected {
  color: red !important;
}
.index /deep/ .van-picker__confirm {
  color: red !important;
}
.van-cell {
  padding: 10px 0px;
}
.title {
  display: flex;
  justify-content: space-between;
  height: 0.88rem;
  padding: 0 0.28rem;
  line-height: 0.88rem;
  background: #fff0e5;
  .left,
  .right {
    font-size: 0.28rem;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #ff3b24;
  }
}
.step {
  margin-top: 0.32rem;
  padding: 0 1.12rem;
  display: flex;
  align-items: center;
  .step_1,
  .step_2 {
    width: 0.42rem;
    height: 0.42rem;
    border-radius: 50%;
    background: #ff3b24;
    text-align: center;
    line-height: 0.42rem;
  }
  .step_xian {
    flex: 1;
    height: 8px;
    background: linear-gradient(270deg, #ffc4bd 0%, #ff3b24 100%);
  }
  .step_1 {
    transform: translateX(0.02rem);
    color: #fff;
  }
  .step_2 {
    transform: translateX(-0.02rem);
    color: #fff;
    font-size: 0.28rem;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #ffffff;
    background: #ff3b24;
    opacity: 0.3;
  }
}
.step_text {
  margin-bottom: 0.8rem;
  margin-top: 0.21rem;
  display: flex;
  justify-content: space-between;
  padding: 0 0.35rem;
  .step_text_1 {
    font-size: 0.28rem;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #222222;
  }
  .step_text_2 {
    font-size: 0.28rem;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #acb5c8;
  }
}
.index /deep/ input::placeholder {
  font-size: 0.3rem;
}
//输入框
.item1 {
  margin: 0 0.2rem 0.57rem;
  border-bottom: 0.03rem solid #f2f2f2;
  .label {
    font-size: 0.28rem;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    span {
      margin-right: 0.1rem;
      color: #ff3b24;
    }
  }
  .name {
    display: flex;
    align-items: center;

    height: 1.12rem;
  }
}
//选择框
.item2 {
  margin: 0 0.2rem 0.57rem;
  border-bottom: 0.03rem solid #f2f2f2;
  .label {
    font-size: 0.28rem;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    span {
      margin-right: 0.1rem;
      color: #ff3b24;
    }
  }
  .name {
    height: 1.12rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    div {
      font-size: 0.3rem;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #c8c8c8;
    }
  }
  .solt {
    display: flex;
    justify-content: space-between;
    margin-bottom: 0.2rem;
    .btn1 {
      text-align: center;
      line-height: 0.71rem;
      box-sizing: border-box;
      width: 3.32rem;
      height: 0.71rem;
      background: #fff0e5;
      border-radius: 0.03rem;
      border: 0.02rem solid #ff3b24;
      font-size: 0.24rem;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #ff3b24;
    }
    .btn2 {
      text-align: center;
      line-height: 0.71rem;
      box-sizing: border-box;
      width: 3.32rem;
      height: 0.71rem;
      background: #f1f0ef;
      font-size: 0.24rem;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #6d6b6b;
      border-radius: 0.03rem;
    }
  }
}
.footer {
  position: fixed;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 0.29rem;
  left: 0;
  width: 100%;
  bottom: 0;
  height: 1.56rem;
  border-top: 0.02rem solid #f2f2f2;
  background: #ffffff;
  .btn1 {
    text-align: center;
    line-height: 0.95rem;
    width: 1.97rem;
    height: 0.95rem;
    background: #ffffff;
    border-radius: 0.1rem;
    border: 0.03rem solid #c3c3c3;
    font-size: 0.32rem;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #989898;
  }
  .btn2 {
    text-align: center;
    line-height: 0.95rem;
    width: 2.36rem;
    height: 0.95rem;
    background: #ffffff;
    border-radius: 0.1rem;
    border: 0.03rem solid #c3c3c3;
    font-size: 0.32rem;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #989898;
  }
  .btn3 {
    text-align: center;
    line-height: 0.95rem;
    width: 2.36rem;
    height: 0.95rem;
    background: #ff3b24;
    border-radius: 0.1rem;
    font-size: 0.32rem;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #ffffff;
  }
}
</style>